import useSoundLibrary from "use-sound";
import { soundSetting as serviceSoundSetting } from "service";
import {
  GameOver,
  SoundClickButton,
  SoundDuplicate,
  SoundGreenLine,
  SoundOrangeTile,
  SoundOrangeTileDelay,
  CricketSoundSuspense777,
  CountUpWin,
  JackpotSound,
  SwooshSound,
  TripleSevenLastHit,
  SideBetWinner,
  CricketBatImpact,
  BigWheelGremlinLaunch,
  BigWheelSpinClick,
  BigWheelWheelSpin,
  SoundOrangeClick,
  BigWheelGremlinLaugh2,
  BigWheelLineWin,
  BigWheelFreegame,
} from "assets/audio";
import { createEmptySoundStub } from "service/sound";

export const useSound = (soundEnabled) => {
  const soundSetting = {
    ...serviceSoundSetting,
    soundEnabled
  };
  // const
  const [playSuspense777, { stop: stopSuspense777 }] = useSoundLibrary(CricketSoundSuspense777, { ...soundSetting, loop: true });
  const [playSoundOrangeTile] = useSoundLibrary(SoundOrangeTile, soundSetting);
  const [playSoundClickButton] = useSoundLibrary(SoundClickButton, soundSetting);
  const [playSoundOrangeTileDelay] = useSoundLibrary(
    SoundOrangeTileDelay,
    soundSetting
  );
  const [playSoundOrangeClick] = useSoundLibrary(SoundOrangeClick, soundSetting);
  const playSoundRolling = createEmptySoundStub();
  const stopSoundRolling = createEmptySoundStub();
  const [playSoundDuplicate] = useSoundLibrary(SoundDuplicate, soundSetting);
  const [playSideBetWinner] = useSoundLibrary(SideBetWinner, soundSetting);
  const [playSoundGreenLine] = useSoundLibrary(SoundGreenLine, soundSetting);
  const playSoundStrike = createEmptySoundStub();
  const playSoundBoo = createEmptySoundStub();
  const playSoundWin = createEmptySoundStub();

  const [playCountUpWin] = useSoundLibrary(CountUpWin, soundSetting);
  const [playGameOver] = useSoundLibrary(GameOver, soundSetting);
  const [playLineWin, { stop: stopLineWin }] = useSoundLibrary(BigWheelLineWin, soundSetting);
  const playJackpotSound = createEmptySoundStub();
  const [playSwooshSound] = useSoundLibrary(SwooshSound);
  const [playWinSound] = useSoundLibrary(TripleSevenLastHit, soundSetting);
  const playBallThrow = createEmptySoundStub();
  const playBatImpact = createEmptySoundStub();
  const playCrowdCheer = createEmptySoundStub();
  const stopCrowdCheer = createEmptySoundStub();
  const playBallsFillingHopper = createEmptySoundStub();
  const stopPlayBallsFillingHopper = createEmptySoundStub();
  const playSportRunningSound = createEmptySoundStub();
  const playOut = createEmptySoundStub();
  const [playGremlinLaunch] = useSoundLibrary(BigWheelGremlinLaunch, soundSetting);
  const [playSpinClick] = useSoundLibrary(BigWheelSpinClick, soundSetting);
  const [playWheelSpin, { stop: stopWheelSpin }] = useSoundLibrary(BigWheelWheelSpin, soundSetting);
  const [playGremlinLaugh2, { stop: stopGremlinLaugh2 } ] = useSoundLibrary(BigWheelGremlinLaugh2, soundSetting);
  const [playFreeGame] = useSoundLibrary(BigWheelFreegame, soundSetting);

  return {
    playSuspense777,
    stopSuspense777,
    playSoundOrangeTile,
    playSoundClickButton,
    playSoundOrangeTileDelay,
    playSoundOrangeClick,
    playSoundRolling,
    stopSoundRolling,
    playSoundDuplicate,
    playSideBetWinner,
    playSoundGreenLine,
    playSoundStrike,
    playSoundBoo,
    playCountUpWin,
    playGameOver,
    playJackpotSound,
    playSwooshSound,
    playTripleSevenLastStrike: () => {},
    playBallThrow,
    playBatImpact,
    playBallsFillingHopper,
    stopPlayBallsFillingHopper,
    playSportRunningSound,
    playCrowdCheer,
    stopCrowdCheer,
    playWinSound,
    playSoundWin,
    playOut,
    playGremlinLaunch,
    playSpinClick,
    playWheelSpin,
    stopWheelSpin,
    playGremlinLaugh2,
    stopGremlinLaugh2,
    playLineWin,
    stopLineWin,
    playFreeGame,
  };
}

export default useSound